<template>
  <v-container>
    <v-row
      justify="center"
      v-if="!variations"
      class="no-print"
    >
      <v-col
        cols="auto"
        class="title"
      > 
        {{ $t('noTablesSetForSelectedLocation') }}
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col
        cols="12"
        class="title"
      >
        {{ location.label }} {{ $t('tablesLC') }}
      </v-col>
    </v-row>
    <v-row class="no-print">
      <v-col cols="12">
        <iframe
          style="height: 297mm; width: 210mm; background-color: #FFFFFF; border: none;"
          :srcdoc="iframeSrc"
          id="frame"
        />
      </v-col>
    </v-row>
    <v-row
      class="no-print"
      justify="center"
    >
      <v-col cols="auto">
        <v-btn
          large
          @click="printElement"
        >
          {{ $t('print') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      style="height: 297mm; width: 210mm;"
      class="overflow-y-auto"
      id="prints"
    >
      <template v-for="variation in variations">
        <v-col
          :key="variation.label"
          style="width: 70mm; height: 74.25mm; border: 2px dashed black; background-color: #FFFFFF;"
        >
          <div class="text-center title">
            {{ variation.label }}
          </div>
          <div>
            <img
              style=" width: 60mm; height: 60mm; margin-top: 0px; background: #fff; padding: 5px;"
              :src="imgSrc(variation.uuid)"
            >
          </div>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
	name: "QRPrint",
	data() {
		return {
			gettingData: false
		}
	},
	beforeMount() {
		this.gettingData = true
		this.$store.dispatch('getEtags').finally(() => {
			this.gettingData = false
		})
	},
	computed: {
		location() {
			return this.$store.state.locations.find(location => { return location.uuid === this.$route.params.uuid })
		},
		zones() {
			return this.$store.state.zones
		},
		variations() {
			if(this.location === null) {
				return []
			} else {
				return this.location.variations
			}
		},
		etags() {
			return this.$store.state.etags
		},
		header() {
			return '<html><head>' +
					'<meta charset="utf-8">' +
					'<link href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap" rel="stylesheet">' +
					'</head><body style="margin: 0; padding: 0; text-align: center; vertical-align: middle; -webkit-font-smoothing: antialiased;">' +
					'<div style="display: flex; flex-wrap: wrap; -webkit-box-flex: 1; flex: 1 1 auto; height: 297mm; width: 210mm;">'
		},
		footer() {
			return '</div></body></html>'
		},
		organizationUUID() {
			return this.$store.state.organization.uuid
		},
		variationsHtml() {
			let html = ''
			for(let i = 0; i < this.variations.length; i++) {
				let table = this.variations[i]
				html += this.tableHtml(table)
			}
			return html
		},
		iframeSrc() {
			return this.header + this.variationsHtml + this.footer
		}
	},
	methods: {
		tableLink(tableId) {
			return 'https://guests.salescloud.is/?organization=' + this.organizationUUID + '&l=' + this.location.uuid + '&table=' + tableId
		},
		tableLinkQr(tableLink) {
			return 'https://europe-west1-proton-mainframe.cloudfunctions.net/generateQRCode?qrContent=' + tableLink
		},
		imgSrc(tableId) {
			let tableLink = encodeURIComponent(this.tableLink(tableId))
			let qr = this.tableLinkQr(tableLink)
			return qr
		},
		tableHtml(table) {
			const tableLabel = table.label
			const tableLink = encodeURIComponent(this.tableLink(table.uuid))
			const tableLinkQr = this.tableLinkQr(tableLink)
			return '<div style="flex-basis: 0; -webkit-box-flex: 1; flex-grow: 1; max-width: 100%; margin: 0 auto; height: 74.25mm; width: 70mm; border: 3px dashed #000000;">' +
					'<div style="padding-left: 10px; padding-right: 10px;">' +
					'<div style="font-size: 24px; text-align: center; padding-top: 10px;">' + tableLabel +  '</div>' +
					'<img style=" width: 60mm; height: 60mm; margin-top: 0px; background: #fff; padding: 5px;" src="' + tableLinkQr + '" />' +
					'</div>'
		},
		printElement() {
		}
	}
}
</script>

<style scoped>

</style>